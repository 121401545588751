import React, { createContext, useState, useContext } from 'react';

// Crea el contexto
const AuthContext = createContext();

// Proveedor de contexto
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('token') !== null);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [nit, setNit] = useState(localStorage.getItem('nit') || ''); // Recuperar nit del localStorage

  const login = (newToken, newNit) => {
    setIsAuthenticated(true);
    setToken(newToken);
    setNit(newNit); // Guarda el NIT
    localStorage.setItem('token', newToken);  // Guarda token en localStorage
    localStorage.setItem('nit', newNit); // Guarda nit en localStorage
  };

  const logout = () => {
    setIsAuthenticated(false);
    setToken(null);
    setNit(''); // Limpia el NIT
    localStorage.removeItem('token');  // Elimina token del localStorage
    localStorage.removeItem('nit');    // Elimina nit del localStorage
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, nit, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Exportar AuthContext para su uso en otros archivos
export { AuthContext };

// Hook para usar el contexto
export const useAuth = () => {
  return useContext(AuthContext); // Este hook usa el contexto de AuthProvider
};




