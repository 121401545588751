import React, { useState } from 'react';
import useAuth from '../hooks/useAuth'; // Importa como default
import CarQR from '../assets/ParkingBuild.jpeg'; 
import '../App.css'; 
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const { isAuthenticated, login } = useAuth(); // Ahora puedes usarlo correctamente
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [nit, setNit] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
 // const [showModal, setShowModal] = useState(false); // Controla el modal
 // const [tokenData, setTokenData] = useState(null); // Almacena el token y nit para mostrar en el modal

  
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_LOGIN_ENDPOINT}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, nit }),
      });
  
      const data = await response.json();
      setLoading(false);
  
      console.log('Data', data); // Verifica que nit no sea undefined
  
      if (data.resultValue === 2) {
        // Guarda el token y nit en localStorage
        localStorage.setItem('token', data.token);
        localStorage.setItem('nit', data.nit);
  
        // Establecer autenticación en contexto
        login(data.token, data.nit);
  
        console.log('NIT antes de navegar:', data.nit);
  
        // Navegar a Main
        navigate('/main');
      } else {
        setMessage(data.message || 'Error desconocido.');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
      setMessage('Ocurrió un error al intentar iniciar sesión.');
    }
  };
  

  return (
    
    <form onSubmit={handleLogin} className="login-container">
      <div className="form-left">
        <h2 className="centered-title">BIENVENIDO</h2>
        <div className="group">
          <label htmlFor="nit">Conjunto Residencial (NIT)</label>
          <input
            type="text"
            id="nit"
            value={nit}
            onChange={(e) => {
              // Filtrar solo números
              const value = e.target.value.replace(/[^0-9]/g, "");
              setNit(value);
            }}
            required
            className="input-field"
            placeholder="Ingresa el NIT del conjunto"
          />
        </div>

        <div className="group">
          <label htmlFor="email">Correo</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
            required
            className="input-field"
            placeholder="Ingresa el correo electrónico"
          />
        </div>

        <div className="group">
          <label htmlFor="password">Contraseña</label>
          <div className="input-wrapper">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="input-field"
              placeholder="Ingresa la contraseña"
            />
          </div>
        </div>

        <div className="toggler">
          <input
            id="toggler-1"
            name="toggler-1"
            type="checkbox"
            checked={showPassword}
            onChange={() => setShowPassword(!showPassword)}
          />
          <label htmlFor="toggler-1"></label>
        </div>

        {message && <p className="message-label">{message}</p>}

        <button type="submit" disabled={loading}>Inicia Sesión</button>
        {loading && <p>Cargando...</p>}

        <div className="forgot-password">
          <a href="#">¿Olvidaste tu contraseña?</a>
        </div>
      </div>

      <div className="form-right">
        <img src={CarQR} alt="Descripción de la imagen" className="login-image" />
      </div>
    </form>
  );
};

export default Login;
