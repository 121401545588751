import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Asegúrate de tener tus estilos aquí
import logo from '../assets/LogoParquear.png';

const Header = ({ isAuthenticated }) => {
  return (
    <header className="app-header">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <nav>
        <ul></ul>
      </nav>
      {!isAuthenticated && ( // Muestra el botón solo si no está autenticado
        <div className="auth-container">
          <label className="auth-message">¿Aún no tienes una cuenta?</label>
          <div className="button-container">
            <Link to="/registro" className="button type1">
              <span className="btn-txt">Regístrate</span>
            </Link>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;

