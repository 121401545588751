import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LogoParquear from './assets/LogoParquear.png'; // Asegúrate de que la ruta sea correcta
import { useNavigate } from 'react-router-dom';





//import { useLocation } from 'react-router-dom';

const Main = () => {
  //const location = useLocation();
  const [notifications, setNotifications] = useState(3); // Simulando 3 notificaciones
  const [nombreConjunto, setNombreConjunto] = useState('');
  const [user, setUser] = useState({ name: '', role: 'Administrador' });
  const [isHovered, setIsHovered] = useState(false);
  const [isUserHovered, setIsUserHovered] = useState(false);
  const navigate = useNavigate(); // Usamos useNavigate para redirigir



  useEffect(() => {
    const nitFromStorage = localStorage.getItem('nit'); // Usar nit desde localStorage
    console.log('nit en el fecth',nitFromStorage);
    if (nitFromStorage) {
      const fetchNombreConjunto = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_REP_CONJUNTO_ENDPOINT}/${nitFromStorage}`);
          const data = await response.json();
  
          if (response.ok) {
            setNombreConjunto(data.nombreConjunto);
            const fullName = `${data.nombres} ${data.apellidos}`;
            setUser({ name: fullName, role: 'Administrador' });
          } else {
            console.error('Error al obtener el nombre del conjunto:', data.message);
          }
        } catch (error) {
          console.error('Error en la consulta al servidor:', error);
        }
      };
  
      fetchNombreConjunto();
    }
  }, []);
  

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Elimina el token
    setUser({ name: '', role: '' }); // Limpiar el estado del usuario
    window.location.href = '/login'; // Redirigir de forma forzada a la página de login
  };
  
  


  
  return (
    <div style={styles.container}>
      {/* Header */}
      <header style={styles.header}>
        <div
          style={styles.userInfo}
          onMouseEnter={() => setIsUserHovered(true)} // Al pasar el ratón, mostrar el submenú
          onMouseLeave={() => setIsUserHovered(false)} // Al salir el ratón, ocultar el submenú
        >
          <span style={styles.userName}>
            {user.name} ({user.role})
          </span>

          {/* Submenú debajo del nombre de usuario */}
          {isUserHovered && (
            <div style={styles.dropdownMenu}>
              <Link to="/opcion-a" style={styles.menuItem}>
                Gestionar Configuración
              </Link>

              {/* Cerrar sesión como un Link con onClick */}
              <Link
                to="#"
                onClick={handleLogout}  // Llamar a handleLogout al hacer clic
                style={styles.menuItem}
              >
                Cerrar Sesión
              </Link>
              </div>
          )}
        </div>

      <div style={styles.notifications}>
          <span style={styles.notificationIcon}>
            <i className="fa fa-bell"></i> {/* Icono de notificación */}
          </span>
          {notifications > 0 && (
            <span style={styles.notificationCount}>{notifications}</span>
          )}
        </div>


        <nav style={styles.navbar}>
          <ul style={styles.navList}>
            <li><Link to="/vehiculos" style={styles.navLink}>Vehículos</Link></li>
            <li><Link to="/propietarios" style={styles.navLink}>Propietarios</Link></li>
            <li style={styles.dropdown} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                {/* Usamos un span para que no navegue cuando se haga clic */}
                <span style={styles.navLink}>Reportes</span>
              <div style={{ ...styles.dropdownMenu, display: isHovered ? 'block' : 'none' }}>
               <Link to="/reporte-vehiculos" style={styles.menuItem}>Vehículos</Link>
              <Link to="/reporte-propietarios" style={styles.menuItem}>Propietarios</Link>
             
              </div>
            </li>
          </ul>
        </nav>

     </header>

      {/* Contenido principal */}
      <div style={styles.mainContent}>
      <h2>BIENVENIDO A  {nombreConjunto ? nombreConjunto.toUpperCase() : 'LA PÁGINA PRINCIPAL'}</h2>
         {/* Imagen debajo del contenido principal */}
          <div className="login-container">
            <img src={LogoParquear} alt="Logo Parquear" style={styles.logo} />
          </div>
      </div>

     
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
   // minHeight: '100vh',
    Height: '100%',
  },
  
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: '#bb2c2c', // Header Rojo
    color: 'white',
    borderRadius: '10px', // Ajusta el valor para más o menos redondeo
  },
  
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    position: 'relative',
  },
  
  navbar: {
    display: 'flex',
    gap: '20px',
  },
  
  navList: {
    listStyle: 'none',
    display: 'flex',
    gap: '20px',
  },
  
  navLink: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '16px',
    padding: '10px',
  },

  dropdown: {
    position: 'relative', // Necesario para mostrar el menú desplegable
  },

  dropdownMenu: {
    position: 'absolute',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    top: '30px', // Ajusta la distancia entre el enlace y el menú
    left: '0',
    zIndex: '1000',
    width: '200px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    marginTop: '1px', // Espaciado entre el enlace y el menú
  },

  menuItem: {
    display: 'block',
    padding: '10px',
    color: 'black',
    textDecoration: 'none',
    borderBottom: '1px solid #ccc',
  },
  mainContent: {
    padding: '20px',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px', // Ajusta el espaciado entre el contenido y la imagen
  },
  logo: {
    width: '100%',  // Ajusta el tamaño de la imagen para que ocupe todo el ancho de la página
    maxWidth: '100%', // Asegura que la imagen no se desborde
    height: 'auto',  // Mantiene la proporción
  },
};

export default Main;

