import React, { useState } from 'react';
import '../App.css'; // Asegúrate de importar los estilos globales
import { jwtDecode } from 'jwt-decode';


const VehiculosForm = () => {
  const [placa, setPlaca] = useState('');
  const [marca, setMarca] = useState('');
  const [linea, setLinea] = useState('');
  const [modelo, setModelo] = useState('');
  const [color, setColor] = useState('');
  const [cedula, setCedula] = useState('');
  const [idTipoVehiculo, setIdTipoVehiculo] = useState('');
  const [fechaFinSoat, setFechaFinSoat] = useState('');
  const [noParqueadero, setNoParqueadero] = useState(''); // Nuevo estado
  const [message, setMessage] = useState('');  // Declaramos el estado para el mensaje

  const marcas = [
    'AKT', 'AUDI', 'BAJAJ', 'BMW', 'CHEVROLET', 'CHERY', 'CHINA', 'CITROEN', 'FIAT', 
    'FORD', 'HARLEY', 'HONDA', 'HYUNDAI', 'KAWASAKI', 'KIA', 'MAZDA', 'MERCEDES-BENZ', 'MITSUBISHI',
    'NISSAN', 'PEUGEOT', 'RAM', 'RENAULT', 'ROYALE ENFIELD', 'SUZUKI', 'TOYOTA', 'VOLKSWAGEN', 'YAMAHA', 
    'XIAOMI', 'NIU', 'SEGWAY', 'URB-E', 'INMOTION', 'TRIUMPH', 'TRONX', 'SMOOS', 'E-TWOW'
];


  const colores = [
   'AMARILLO', 'AZUL', 'BEIGE', 'BLANCO', 'DORADO', 'GRIS', 'MARRÓN', 'NEGRO', 'NARANJA', 'PLATA', 'PLATEADO', 'ROJO', 'ROSA', 'TURQUESA', 'VIOLETA', 'VERDE'
  ];

  const modelos = [
    '2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016',
    '2015', '2014', '2013', '2012', '2011', '2010', '2009', '2008', '2007', '2006',
    '2005', '2004', '2003', '2002', '2001', '2000', '1999', '1998', '1997', '1996',
    '1995', '1994', '1993', '1992', '1991', '1990', '1989', '1988', '1987', '1986',
    '1985', '1984', '1983', '1982', '1981', '1980', '1979', '1978', '1977', '1976',
    '1975', '1974', '1973', '1972', '1971', '1970', '1969', '1968', '1967', '1966',
    '1965', '1964', '1963', '1962', '1961', '1960', '1959', '1958', '1957', '1956',
    '1955', '1954', '1953', '1952', '1951', '1950', '1949', '1948', '1947', '1946',
    '1945', '1944', '1943', '1942', '1941', '1940', '1939', '1938', '1937', '1936',
    '1935', '1934', '1933', '1932', '1931', '1930', '1929', '1928', '1927', '1926',
    '1925', '1924', '1923', '1922', '1921', '1920'
  ];
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
        /* Obtener el token desde el localStorage
      const token = localStorage.getItem('token');
      // Verificar si el token existe y tiene el formato adecuado
      if (!token || token.split('.').length !== 3) {
        console.error('Token no válido');
        setMessage('Token no válido');
        return; // Detener la ejecución si el token no es válido
      }*/


  
      let nit = localStorage.getItem('nit'); // Usar nit desde localStorage
      console.log('nit en el insert vehiculo ',nit);
 
 
    try {
      const response = await fetch (`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_ADD_VEHICULO_ENDPOINT}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        
        body: JSON.stringify({
          placa, marca, modelo, linea, color, cedula, nit, idTipoVehiculo, fechaFinSoat, noParqueadero,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setMessage(data.message);
  
        if (data.message === 'VEHICULO INSERTADO CORRECTAMENTE') {
          setPlaca('');
          setMarca('');
          setLinea('');
          setModelo('');
          setColor('');
          setCedula('');
          setIdTipoVehiculo('');
          setFechaFinSoat('');
          setNoParqueadero('');
        } else {
          setMessage(data.message || 'ERROR DESCONOCIDO');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('ERROR INESPERADO AL REGISTRAR EL PROPIETARIO');
    }
  };
  

  

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="form-left">
        <h2>REGISTRO DE VEHÍCULOS</h2>
        
        {/* Formulario */}
        <div className="group">
          <label>Cédula Propietario:</label>
          <input
            type="text"
            value={cedula}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setCedula(value);
              }
            }}
            required
            className="input-field"
            placeholder="Ingresa la cédula del propietario ej. 100254875"
          />
        </div>

        <div className="group">
          <label>Placa:</label>
          <input
            type="text"
            value={placa}
            onChange={(e) => {
              const value = e.target.value.toUpperCase();  // Convierte la entrada a mayúsculas
              if (/^[A-Z0-9]*$/.test(value)) {  // Acepta solo letras mayúsculas y números
                setPlaca(value);
              }
            }}
            required
            className="input-field"
            maxLength="6"  // Limita el campo a 6 caracteres
            placeholder="Ingresa la placa ej. PEK116"
          />
        </div>


        <div className="group">
          <label>Marca:</label>
          <select
            value={marca}
            onChange={(e) => setMarca(e.target.value)}
            required
            className="input-field"
          >
            <option value="">Seleccionar Marca</option>
            {marcas.map((marca, index) => (
              <option key={index} value={marca}>
                {marca}
              </option>
            ))}
            <option value="otro">Otro...</option>
          </select>
        </div>

        <div className="group">
          <label>Línea:</label>
          <input
            type="text"
            value={linea}
            onChange={(e) => setLinea(e.target.value)}
            required
            className="input-field"
            placeholder="Ingresa la línea ej. Q4"
          />
        </div>

        <div className="group">
          <label>Modelo:</label>
          <select
            value={modelo}
            onChange={(e) => setModelo(e.target.value)}
            required
            className="input-field">
            <option value="">Selecciona el modelo</option>
            {modelos.map((modeloOption) => (
              <option key={modeloOption} value={modeloOption}>
                {modeloOption}
              </option>
            ))}
          </select>
        </div>

        <div className="group">
          <label>Color:</label>
          <select
            value={color}
            onChange={(e) => setColor(e.target.value)}
            required
            className="input-field"
          >
            <option value="">Seleccionar Color</option>
            {colores.map((color, index) => (
              <option key={index} value={color}>
                {color}
              </option>
            ))}
            <option value="otro">Otro...</option>
          </select>
        </div>

        <div className="group">
          <label>Tipo de Vehículo:</label>
          <select
            value={idTipoVehiculo}
            onChange={(e) => setIdTipoVehiculo(e.target.value)}
            required
            className="input-field"
          >
            <option value="">Seleccionar Tipo de Vehículo</option>
            <option value="1">CARRO</option>
            <option value="2">MOTO</option>
            <option value="3">CAMIONETA</option>
            <option value="4">BICICLETA</option>
            <option value="5">PATINETA ELECTRICA</option>
          </select>
        </div>

        <div className="group">
          <label>Fecha Fin SOAT:</label>
          <input
            type="date"
            value={fechaFinSoat}
            onChange={(e) => setFechaFinSoat(e.target.value)}
            required
            className="input-field"
          />
        </div>

        {/* Nuevo campo de entrada No_parqueadero */}
        <div className="group">
          <label>No Parqueadero:</label>
          <input
            type="text"
            value={noParqueadero}
            onChange={(e) => setNoParqueadero(e.target.value)}
            required
            className="input-field"
            placeholder="Ingresa el número de parqueadero"
          />
        </div>

         {/* Mostrar el mensaje de respuesta del backend */}
         {message && <p className="message-label">{message}</p>}


        <button type="submit" className="btn-submit">
          Agregar Vehículo
        </button>
      </form>
    </div>
  );
};

export default VehiculosForm;


