import React from 'react';
import { Navigate } from 'react-router-dom'; // Asegúrate de que esté importado
import useAuth from '../hooks/useAuth'; // Tu hook de autenticación

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    // Si no está autenticado, redirigir a la página de login
    return <Navigate to="/" />;
  }

  return children;
};

export default PrivateRoute;


