import React from 'react';
import './Footer.css'; // Si deseas agregar estilos personalizados
import LogoFacebook from '../assets/LogoFacebook.png'; // Asegúrate de ajustar la ruta
import LogoInstagram from '../assets/LogoInstagram.png';
import LogoX from '../assets/LogoX.png';
import LogoYoutube from '../assets/LogoYoutube.png';

const Footer = () => {
  return (
    <footer className="app-footer">
      <p>&copy; 2024 ParQueaR Web. Todos los derechos reservados.</p>
      <div className="social-media">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <img src={LogoFacebook} alt="Facebook" className="social-icon" />
        </a>
        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
          <img src={LogoX} alt="Twitter" className="social-icon" />
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <img src={LogoInstagram} alt="Instagram" className="social-icon" />
        </a>
        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
          <img src={LogoYoutube} alt="YouTube" className="social-icon" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;

