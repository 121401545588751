import React, { useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import './VehiculosContainer.css';
import editIcon from '../assets/editar.png'; // Importa el ícono al inicio del archivo
import cancelIcon from '../assets/cancelar.png'; // Importa el ícono al inicio del archivo
import saveIcon from '../assets/Guardar.png'; // Importa el ícono al inicio del archivo

const Reportepropietarios = () => {
 // const { nit } = useAuth();
  const [propietarios, setPropietarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [filters, setFilters] = useState({
    cedula: '',
    nombres: '',
    apellidos: '',
    telefono: '',
    email: '',
    torre: '',
    apto: '',
  });

  const filteredPropietarios = propietarios.filter((propietario) => {
    return (
      (propietario.DOCUMENTO_IDENTIDAD || '').toLowerCase().includes(filters.cedula.toLowerCase()) &&
      (propietario.NOMBRE || '').toLowerCase().includes(filters.nombres.toLowerCase()) &&
      (propietario.APELLIDOS || '').toLowerCase().includes(filters.apellidos.toLowerCase()) &&
      (propietario.TELEFONO || '').toLowerCase().includes(filters.telefono.toLowerCase()) &&
      (propietario.EMAIL || '').toLowerCase().includes(filters.email.toLowerCase()) &&
      (propietario.TORRE || '').toLowerCase().includes(filters.torre.toLowerCase()) &&
      (propietario.APTO || '').toLowerCase().includes(filters.apto.toLowerCase())
    );
  });

  useEffect(() => {
    const nitFromStorage = localStorage.getItem('nit'); // Usar nit desde localStorage
    //console.log('nit en el fecth',nitFromStorage);

    const fetchPropietarios = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_REP_PROPIETARIOS_ENDPOINT}/${nitFromStorage}`);
        const data = await response.json();
        if (response.ok) {
          setPropietarios(data);
        }
      } catch (error) {
        console.error('Error al cargar los propietarios', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPropietarios();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleEdit = (index) => {
    setEditRowIndex(index);
  
    // Asegúrate de mapear las claves correctamente al formato esperado
    setEditedData({
      DOCUMENTO_IDENTIDAD: filteredPropietarios[index].DOCUMENTO_IDENTIDAD || '',
      NOMBRE: filteredPropietarios[index].NOMBRE || '',
      APELLIDOS: filteredPropietarios[index].APELLIDOS || '',
      TELEFONO: filteredPropietarios[index].TELEFONO || '',
      EMAIL: filteredPropietarios[index].EMAIL || '',
      TORRE: filteredPropietarios[index].TORRE || '',
      APTO: filteredPropietarios[index].APTO || '',

    });
  };
  

  const handleSave = async () => {
    // Transforma los datos para que coincidan con el backend
    const nitStorage = localStorage.getItem('nit'); // Usar nit desde localStorage
   // console.log('nit en el fecth',nitStorage);


    const dataToSend = {
      documento: editedData.DOCUMENTO_IDENTIDAD,
      nombres: editedData.NOMBRE,
      apellidos: editedData.APELLIDOS,
      telefono: editedData.TELEFONO,
      email: editedData.EMAIL,
      torre: editedData.TORRE,
      apto: editedData.APTO,
      nit: nitStorage,
    };
  
    // Verifica que los datos no estén vacíos
    if (
      !dataToSend.documento ||
      !dataToSend.nombres ||
      !dataToSend.apellidos ||
      !dataToSend.telefono ||
      !dataToSend.email ||
      !dataToSend.torre ||
      !dataToSend.apto
    ) {
      console.error('Faltan datos obligatorios:', dataToSend);
      alert('Por favor, completa todos los campos obligatorios.');
      return;
    }
  
    //console.log("Datos enviados al backend:", dataToSend);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/edit-owner`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(dataToSend),
      });
  
      if (response.ok) {
        const updatedData = [...propietarios];
        updatedData[editRowIndex] = { ...editedData };
        setPropietarios(updatedData);
        setEditRowIndex(null);
        setEditedData({});
        //alert('Propietario actualizado con éxito.');
      } else {
        console.error('Error al actualizar el propietario');
        alert('Hubo un error al actualizar el propietario.');
      }
    } catch (error) {
      console.error('Error en la actualización:', error);
      alert('Error en la conexión al servidor.');
    }
  };
  

  const handleCancel = () => {
    setEditRowIndex(null);
    setEditedData({});
  };

  const handleInputChange = (e, isUpperCase = false, isLowerCase = false, isNumeric = false) => {
    const { name, value } = e.target;
  
    let newValue = value;
  
    // Si isUpperCase es true, convierte el valor a mayúsculas
    if (isUpperCase) {
      newValue = value.toUpperCase();
    } 
    // Si isLowerCase es true, convierte el valor a minúsculas
    else if (isLowerCase) {
      newValue = value.toLowerCase();
    } 
    // Si isNumeric es true, permite solo números
    else if (isNumeric) {
      newValue = value.replace(/[^0-9]/g, ''); // Elimina cualquier caracter que no sea un número
    }
  
    setEditedData({
      ...editedData,
      [name]: newValue,
    });
  };
  
  
  
  return (
    <div>
      <h2>Listado de Propietarios</h2>

      {/* Filtros */}
      <div className="filters">
        {Object.keys(filters).map((filter) => (
          <input
            key={filter}
            className="input-field"
            type="text"
            name={filter}
            placeholder={`Filtrar por ${filter}`}
            value={filters[filter]}
            onChange={handleFilterChange}
          />
        ))}
      </div>

      {/* Mostrar Cargando si los datos están siendo cargados */}
      {loading ? (
        <p>Cargando propietarios...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>CEDULA</th>
              <th>NOMBRES</th>
              <th>APELLIDOS</th>
              <th>TELEFONO</th>
              <th>EMAIL</th>
              <th>TORRE</th>
              <th>APARTAMENTO</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {filteredPropietarios.map((propietario, index) => (
              <tr key={index}>
                {editRowIndex === index ? (
                  <>
                    <td>{propietario.DOCUMENTO_IDENTIDAD}</td>
                    <td>
                    <input
                      type="text"
                      name="NOMBRE"
                      value={editedData.NOMBRE || ''}
                      onChange={(e) => handleInputChange(e, true)} // Agregar la opción para forzar mayúsculas
                    />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="APELLIDOS"
                        value={editedData.APELLIDOS || ''}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="TELEFONO"
                        value={editedData.TELEFONO || ''}
                        onChange={(e) => handleInputChange(e, false, false, true)} // Llamar para solo números
                        maxLength={10}  // Limitar a 10 dígitos
                        pattern="[0-9]*"  // Permitir solo números
                      />
                    </td>

                      <td>
                        <input
                          type="text"
                          name="EMAIL"
                          value={editedData.EMAIL || ''}
                          onChange={(e) => handleInputChange(e, false, true)} // Agregar la opción para forzar minúsculas
                        />
                      </td>

                    <td>
                      <input
                        type="text"
                        name="TORRE"
                        value={editedData.TORRE || ''}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="APTO"
                        value={editedData.APTO || ''}
                        onChange={handleInputChange}
                      />
                    </td>
                    <td>
                      <img
                          src={saveIcon}
                          alt="Grabar"
                          className="icon-button"
                          onClick={() => handleSave(index)}
                        />

                      <img
                          src={cancelIcon}
                          alt="Cancelar"
                          className="icon-button"
                          onClick={() => handleCancel(index)}
                        />
                    </td>
                  </>
                ) : (
                  <>
                    <td>{propietario.DOCUMENTO_IDENTIDAD}</td>
                    <td>{propietario.NOMBRE}</td>
                    <td>{propietario.APELLIDOS}</td>
                    <td>{propietario.TELEFONO}</td>
                    <td>{propietario.EMAIL}</td>
                    <td>{propietario.TORRE}</td>
                    <td>{propietario.APTO}</td>
                      <td>
                        <img
                          src={editIcon}
                          alt="Editar"
                          className="icon-button"
                          onClick={() => handleEdit(index)}
                        />
                      </td>

                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Reportepropietarios;
