import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom'; // Asegúrate de importar useLocation
import Main from './Main';
import InsertVehiculos from './pages/InsertVehiculos';
import ReporteVehiculosxPropietarios from './pages/ReporteVehiculosxPropietarios';
import ReportePropietarios from './pages/ReportePropietarios';
import InsertPropietariosxVehiculos from './pages/InsertPropietariosxVehiculos';
import Header from './components/Header';
import Footer from './components/Footer';
import InsertConjunto from './pages/InsertConjunto'; 
import Login from './pages/Login'; 
import { AuthProvider } from './context/AuthContext'; 
import PrivateRoute from './components/PrivateRoute'; 

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

const AppContent = () => {
  const location = useLocation();  // Obtener la ruta actual

  const appStyle = {  // Define appStyle
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div className="App" style={appStyle}>
      {/* Mostrar Header solo en la ruta de login */}
      {location.pathname === '/' && <Header />}
      
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/main"
          element={
            <PrivateRoute>
              <Main />
            </PrivateRoute>
          }
        />
        <Route
          path="/vehiculos"
          element={
            <PrivateRoute>
              <InsertVehiculos />
            </PrivateRoute>
          }
        />
        <Route
          path="/propietarios"
          element={
            <PrivateRoute>
              <InsertPropietariosxVehiculos />
            </PrivateRoute>
          }
        />
        <Route
          path="/reporte-vehiculos"
          element={
            <PrivateRoute>
              <ReporteVehiculosxPropietarios />
            </PrivateRoute>
          }
        />
        <Route
          path="/reporte-propietarios"
          element={
            <PrivateRoute>
              <ReportePropietarios />
            </PrivateRoute>
          }
        />
        {/* Ruta pública para InsertConjunto */}
        <Route path="/registro" element={<InsertConjunto />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;

