import React, { useState, useEffect } from 'react';
import './VehiculosContainer.css';
import useAuth from '../hooks/useAuth';
import editIcon from '../assets/editar.png'; // Importa el ícono al inicio del archivo
import cancelIcon from '../assets/cancelar.png'; // Importa el ícono al inicio del archivo
import saveIcon from '../assets/Guardar.png'; // Importa el ícono al inicio del archivo

const VehiculosContainer = () => {
  const { nit } = useAuth(); // Obtén el nit del contexto de autenticación

  const [vehiculos, setVehiculos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    placa: '',
    cedula: '',
    tipo: '',
    noparqueadero: '',
  });

  const [editingIndex, setEditingIndex] = useState(null); // Para saber qué fila se está editando
  const [editedVehiculo, setEditedVehiculo] = useState({}); // Para guardar los cambios

  useEffect(() => {
    if (!nit) {
      return;
    }

    const fetchVehiculos = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_REP_VEHICULO_ENDPOINT}/${nit}`);
        const data = await response.json();
        if (response.ok) {
          setVehiculos(data);
        }
      } catch (error) {
        console.error('ERROR CARGANDO LOS VEHICULOS', error);
      } finally {
        setLoading(false);
      }
    };

    if (nit) fetchVehiculos();
  }, [nit]);

  // Filtrar vehículos
  const filteredVehiculos = vehiculos.filter((vehiculo) => {
    return (
      (vehiculo.PLACA_VEHICULO || '').toLowerCase().includes(filters.placa.toLowerCase()) &&
      (vehiculo.DOCUMENTO_IDENTIDAD || '').toLowerCase().includes(filters.cedula.toLowerCase()) &&
      (vehiculo.TIPO_VEHICULO || '').toLowerCase().includes(filters.tipo.toLowerCase()) &&
      (vehiculo.NUMERO_PARQUEADERO || '').toLowerCase().includes(filters.noparqueadero.toLowerCase())
    );
  });

  // Actualizar filtros
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Manejar cambios en los campos editables
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedVehiculo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Activar modo edición
  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditedVehiculo({ ...filteredVehiculos[index] }); // Guardar una copia del vehículo para editar
  };

  // Cancelar edición
  const handleCancel = () => {
    setEditingIndex(null);
    setEditedVehiculo({});
  };

  // Guardar cambios
  const handleSave = async () => {
    const updatedVehiculos = [...vehiculos];
    updatedVehiculos[editingIndex] = editedVehiculo; // Actualiza el vehículo con los nuevos valores

    // Aquí debes enviar los cambios al backend, por ejemplo:
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/edit-car`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(editedVehiculo),
      });

      if (response.ok) {
        setVehiculos(updatedVehiculos); // Actualiza la lista de vehículos en el estado
        setEditingIndex(null); // Salir del modo edición
        setEditedVehiculo({});
      } else {
        console.error('Error al guardar los cambios');
      }
    } catch (error) {
      console.error('Error al guardar los cambios', error);
    }
  };

  return (
    <div>
      <h2>Listado de Vehículos</h2>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <>
          {/* Filtros de búsqueda */}
          <div className="filters">
            <input
              className="input-field"
              type="text"
              name="placa"
              placeholder="Filtrar por Placa"
              value={filters.placa}
              onChange={handleFilterChange}
            />
            <input
              className="input-field"
              type="text"
              name="cedula"
              placeholder="Filtrar por Cédula"
              value={filters.cedula}
              onChange={handleFilterChange}
            />
            <input
              className="input-field"
              type="text"
              name="tipo"
              placeholder="Filtrar por Tipo"
              value={filters.tipo}
              onChange={handleFilterChange}
            />
            <input
              className="input-field"
              type="numeric"
              name="noparqueadero"
              placeholder="Filtrar por Parqueadero"
              value={filters.noparqueadero}
              onChange={handleFilterChange}
            />
          </div>

          {/* Tabla de vehículos */}
          <table>
            <thead>
              <tr>
                <th>PLACA</th>
                <th>CEDULA</th>
                <th>NOMBRES</th>
                <th>APELLIDOS</th>
                <th>MARCA</th>
                <th>LINEA</th>
                <th>MODELO</th>
                <th>TIPO</th>
                <th>COLOR</th>
                <th># </th>
                <th>VENCIMIENTO SOAT</th>
                <th>ACTIVO</th>
                <th></th> {/* Columna para los botones */}
              </tr>
            </thead>
            <tbody>
              {filteredVehiculos.map((vehiculo, index) => (
                <tr key={index}>
                  <td>{vehiculo.PLACA_VEHICULO}</td>
                  <td>{vehiculo.DOCUMENTO_IDENTIDAD}</td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        name="NOMBRE"
                        value={editedVehiculo.NOMBRE || ''}
                        onChange={handleInputChange}
                      />
                    ) : (
                      vehiculo.NOMBRE
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        name="APELLIDOS"
                        value={editedVehiculo.APELLIDOS || ''}
                        onChange={handleInputChange}
                      />
                    ) : (
                      vehiculo.APELLIDOS
                    )}
                  </td>
                  {/* Aquí se editan solo los campos solicitados */}
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        name="MARCA_VEHICULO"
                        value={editedVehiculo.MARCA_VEHICULO || ''}
                        onChange={handleInputChange}
                      />
                    ) : (
                      vehiculo.MARCA_VEHICULO
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        name="LINEA_VEHICULO"
                        value={editedVehiculo.LINEA_VEHICULO || ''}
                        onChange={handleInputChange}
                      />
                    ) : (
                      vehiculo.LINEA_VEHICULO
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        name="MODELO_VEHICULO"
                        value={editedVehiculo.MODELO_VEHICULO || ''}
                        onChange={handleInputChange}
                      />
                    ) : (
                      vehiculo.MODELO_VEHICULO
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        name="TIPO_VEHICULO"
                        value={editedVehiculo.TIPO_VEHICULO || ''}
                        onChange={handleInputChange}
                      />
                    ) : (
                      vehiculo.TIPO_VEHICULO
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="text"
                        name="COLOR_VEHICULO"
                        value={editedVehiculo.COLOR_VEHICULO || ''}
                        onChange={handleInputChange}
                      />
                    ) : (
                      vehiculo.COLOR_VEHICULO
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="number"
                        name="NUMERO_PARQUEADERO"
                        value={editedVehiculo.NUMERO_PARQUEADERO || ''}
                        onChange={handleInputChange}
                      />
                    ) : (
                      vehiculo.NUMERO_PARQUEADERO
                    )}
                  </td>
                  <td>
                    {editingIndex === index ? (
                      <input
                        type="date"
                        name="FECHA_SOAT_FIN"
                        value={editedVehiculo.FECHA_SOAT_FIN || ''}
                        onChange={handleInputChange}
                      />
                    ) : (
                      vehiculo.FECHA_SOAT_FIN
                    )}
                  </td>
                  <td>{vehiculo.VIGENTE ? 'Sí' : 'No'}</td>
                  <td>
                    {editingIndex === index ? (
                      <>
                        <img
                          src={saveIcon}
                          alt="Grabar"
                          className="icon-button"
                          onClick={() => handleSave(index)}
                        />
                        <img
                          src={cancelIcon}
                          alt="Cancelar"
                          className="icon-button"
                          onClick={() => handleCancel(index)}
                        />
                      </>
                    ) : (
                      <img
                        src={editIcon}
                        alt="Editar"
                        className="icon-button"
                        onClick={() => handleEdit(index)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};


export default VehiculosContainer;
