import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext'; // Asegúrate de importar AuthContext

const useAuth = () => {
  return useContext(AuthContext); // Este hook usa el contexto de AuthProvider
};

export default useAuth; // Exporta como default




